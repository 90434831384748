body{
    overscroll-behavior: contain;
    overflow-y: hidden;
}

/*IE9*/
*::selection 
{
    background-color:transparent;
} 
*::-moz-selection
{
    background-color:transparent;
}
*
{        
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    /*IE10*/
    -ms-user-select: none;
    user-select: none;

    /*You just need this if you are only concerned with android and not desktop browsers.*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}  

#root{
    margin: 0px;
    padding: 0px;
    display: block;
    height: 100%;
    background-color: lightgrey;
}
